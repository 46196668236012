<template>
  <page title="咨询详情">
    <article id="page-advinfo">
    <div class="consulting_service_two_banner">
      <div class="consulting_service_two_banner_title">
        <h1>留言咨询</h1>
        <p>为群众提供多种咨询方式的留言咨询。</p>
      </div>
    </div>
    <section class="mt-3 mb-3 overflow-hidden">
      <div class="container">
      <div class="row">
    <div class="consulting_service_two_container tabbox">
      <div class="lsServerLszxltDetail_container">
         <Collection :name="`workorder/${id}`"  v-slot="{ model }">
        <div class="lsServerLszxltDetail_container_title">
          <h2>
            <a-icon type="question-circle" :style="{ fontSize: '32px', color: '#FFCC33'}" /><span class="zxbt">{{model.order_desc}}</span>
          </h2>
        </div>
        <div class="lsServerLszxltDetail_container_text">
          <p><span class="zxnr">{{model.order_desc}}</span></p>
        </div>
           <div class="dealIntro">
             <span class="f_left ng-binding">所在地区：{{model.area}}{{model.city}}</span>
             <div class="f_right">
               <span class="marig16 ng-binding">求助人：{{showPerson(model.sponsor_name)}}</span>
               <span class="ng-binding">时间{{ moment(model.create_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
             </div>
           </div>
         </Collection>
        <div class="commentAll">
          <div class="comment-show">
            <div class="comment-show-con clearfix firstLevel">
              <div class="comment-show-con clearfix">
                <Collection :name="`workorder/${id}/workorder_reply`"  v-slot="{ collection }">
                <div class="comment-show-con-list pull-left clearfix" v-for="value in collection" :key="value.reply_id">
                  <div class="pl-text clearfix">
                    <div class="lawAnswer_tit">
                      <a class="f_left ng-binding"><a-icon type="user" :style="{ fontSize: '16px', color: '#08c' }"/>{{showPerson(value.replyer_name)}}</a>
                    </div>
                    <span class="my-pl-con"
                      >&nbsp;{{value.reply_desc}}</span
                    >
                  </div>
                  <div class="date-dz">
                    <span class="date-dz-left pull-left comment-time"
                      >{{ moment(value.reply_time).format("YYYY-MM-DD HH:mm:ss") }}</span>
                    <div
                      class="date-dz-right pull-right comment-pl-block"
                    ></div>
                  </div>
                  <div class="hf-list-con" id="2204"></div>
                </div>
                </Collection>
              </div>
            </div>
          </div>
          <!--回复区域 end-->
        </div>
      </div>
    </div>
      </div>
      </div>
    </section>
    </article>
  </page>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'adviceInfo',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      modal: {}
    }
  },
  activated () {
    this.id = this.$route.params.id
  }
}
</script>
<style scoped src="../../assets/css/advice.css"></style>
